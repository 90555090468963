













































import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "Box",
  props: {
    title: String,
    subtitle1: String,
    subtitle2: String,
    detailUrl: Object,
  },
});
